<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="iconSize"
    :height="iconSize"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="veke-icon feather feather-chevron-down"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
</template>

<script lang="ts">
import Icon from './Icon'

export default Icon('ChevronDownIcon')
</script>
